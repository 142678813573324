<template>
  <v-select
    :value="valueData"
    :outlined="outlined ? true : false"
    v-model="valueData"
    ref="select"
    :items="items"
    :taggable="taggable"
    :item-value="itemValue"
    :item-text="itemText"
    :placeholder="placeholder"
    :label="placeholder"
    offset
    :menu-props="{
      offsetY: true,
      nudgeBottom: outlined ? '8px' : undefined,
      closeOnContentClick: false,
      offsetOverflow: true,
      contentClass: 'custom-dropdown-select',
    }"
    :required="required"
    class="filter-select custom-select"
    :hide-details="showDetails ? false : true"
    :class="`
    ${bold ? 'v-select--select-bold' : ''} 
    ${block ? 'custom-select--block' : ''}
    v-input--${size} input-dropdown ${customClasses}`"
    v-on="$listeners"
    :return-object="returnObject"
  >
    <template v-slot:no-data>
      <loader />
    </template>

    <template v-slot:prepend-inner>
      <slot name="prepend-inner">
        <div class="v-text-field__prefix" v-if="prefix">
          {{ prefix }}
        </div>
      </slot>
    </template>
    <template v-slot:selection="{ item }">
      <span class="font-weight-bold input-dropdown">{{ item.text ? $t(`form.option.${item.text}`) : item }}</span>
    </template>

    <template v-slot:item="{ item, on, attrs }">
      <v-tooltip
        open-delay="200"
        content-class="tooltip-left-center"
        right
        center
        v-if="item.tooltip"
      >
        <template v-slot:activator="{ on: on2, attrs: attrs2 }">
          <div
            class="w-100"
            style="align-self: stretch"
            v-on="on2"
            v-bind="attrs2"
          >
            <div class="input-dropdown-item" v-on="on" v-bind="attrs">
              <span>{{ item.text ? $t(`form.option.${item.text}`) : item }}</span>
              <v-icon class="ml-2 input-dropdown-item__icon">$check</v-icon>
            </div>
          </div>
        </template>
        {{ item.tooltip }}
      </v-tooltip>

      <div v-else class="input-dropdown-item" v-on="on" v-bind="attrs">
        <span>{{ item.text ? $t(`form.option.${item.text}`) : item }}</span>
        <v-icon class="ml-2 input-dropdown-item__icon">$check</v-icon>
      </div>
    </template>

    <template v-slot:append-item>
      <slot name="append-item"></slot>
    </template>

    <template v-slot:append>
      <v-icon size="16">$chevrondown</v-icon>
    </template>
  </v-select>
</template>

<script>
import Loader from "./Loader.vue";

export default {
  components: { Loader },
  props: {
    bold: {
      type: Boolean,
      default: true,
    },
    prefixIcon: String,
    placeholder: String,
    outlined: {
      type: Boolean,
      default: true,
    },
    customClasses: {
      type: String,
      default: "",
    },
    block: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
    },
    showDetails: {
      type: Boolean,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemValue: {
      type: String,
    },
    itemText: {
      type: String,
    },
    value: {
      type: [String, Number, Object],
      default: "",
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: function (value) {
        return ["xlg", "lg", "md", "sm", "xsm"].indexOf(value) !== -1;
      },
      default: "lg",
    },
    prefix: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    valueData: {
      get: function () {
        return this.value;
      },
      set: function (newData) {
        this.blur();
        this.$emit("update:value", newData);
      },
    },
  },
  methods: {
    blur: function () {
      document.activeElement.blur();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input:not(.custom-select--block) {
  max-width: fit-content;
}
.v-input.custom-select--block {
  width: 100%;
}

.v-input.primary--text:not(.v-select--is-menu-active)::v-deep {
  .v-input__prepend-inner .v-icon svg {
    color: var(--v-gray-darken1) !important;
  }
}

.v-input.primary--text::v-deep {
  .v-input__prepend-inner .v-icon svg {
    transition: color 0.24s ease;
  }
}

.v-select:not(.v-text-input--outlined)::v-deep {
  &.v-select--is-menu-active {
    .v-select__selection {
      color: var(--v-primary-base);
    }
  }
}

.v-select.v-text-input--outlined::v-deep {
  &.v-select--is-menu-active {
    background-color: map-get($primary, lighten4);
    .v-icon__component {
      color: map-get($primary, darken1) !important;
    }
  }
  .v-menu__content {
    display: none;
  }
  .v-input__slot {
    display: flex;
    align-items: center;
  }
}

.input-dropdown::v-deep {
  .v-list-item {
    padding: 0px !important;
  }
  &.v-select--is-menu-active {
    .v-input__append-inner {
      .v-icon {
        color: map-get($primary, darken1) !important;
        transform-origin: center;
        transform: rotate(180deg);
      }
    }
    span {
      color: map-get($primary, darken1) !important;
    }
  }
  legend {
    width: 0px !important;
  }
  .v-label {
    position: static !important;
  }
  .v-label--active {
    transform: none !important;
  }
  &.v-input--is-dirty {
    .v-label--active {
      display: none !important;
    }
  }
}

.v-text-field__prefix {
  margin-top: 2px;
}
.v-list-item {
  padding: 0px !important;
  &::before {
    display: none;
  }
  .input-dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: color 0.24s ease;
    padding: 0 16px;
    .v-icon {
      opacity: 0;
      transition-delay: 0.5s;
      transition: opacity 0.24s ease;
    }
    &:hover {
      .v-icon {
        opacity: 0.5;
      }
    }
    &.input-dropdown-item[aria-selected="true"] {
      font-weight: $font-weight-semibold;

      .v-icon {
        opacity: 1;
      }
    }
    &.input-dropdown-item[aria-selected="true"] {
      color: map-get($primary, darken1);

      &::before {
        background-color: transparent;
      }
    }
    &--error {
    }
  }
}
</style>
