<template>
  <v-dialog
    hide-overlay
    :overlay-opacity="0"
    transition="fade-transition"
    persistent
    ref="dialog"
    width="560px"
    v-model="isOpen"
    absolute
  >
    <div class="card-overlay" @click="$refs.dialog.animateClick()" />

    <v-card class="" v-if="isOpen">
      <div class="pa-6 d-flex align-center justify-center">
        <OnboardingNoActivePlans />
      </div>

      <v-divider />

      <v-card-text height="800px" class="px-10 pt-8">
        <v-btn x-small icon @click="$emit('removeModal')" class="close-model">
          <v-icon>$close</v-icon>
        </v-btn>
        <h4 class="text-center pb-6">{{ $t('heading.onboarding.limit.title') }}</h4>
        <p class="p-1 text-center">
          <i18next :translation="$t('heading.onboarding.limit.info.general')">
            <template #limit>
              <b>{{ selectedService.instance_limit }}</b>
            </template>
          </i18next>
          <template v-if="upgradePlanLink">
            <i18next :translation="$t('heading.onboarding.limit.info.order.plan')">
              <template #link>
                <a :href="upgradePlanLink" target="_blank" class="info--text">{{ $t('general.here') }}</a>
              </template>
            </i18next>
          </template>
          <template v-else-if="orderNewServiceLink">
            {{ $t('heading.onboarding.limit.info.order.service') }}
          </template>
        </p>
      </v-card-text>
      <v-card-actions class="d-flex flex-column pb-4 px-10 pt-2">
        <v-btn
          v-if="upgradePlanLink"
          :href="upgradePlanLink"
          target="_blank"
          x-large
          elevation="0"
          color="primary"
          block
        >
          {{ $t('button.service.upgrade') }}
        </v-btn>
        <v-btn v-else-if="orderNewServiceLink" :href="orderNewServiceLink" target="_blank" x-large elevation="0" color="primary" block>
          {{ $t('button.plan.order') }}
        </v-btn>
        <v-btn
          x-large
          elevation="0"
          class="mx-0 mt-2"
          @click="$emit('goBack')"
          color="gray"
          text
          block
        >
          <span v-if="!upgradePlanLink && !orderNewServiceLink" class="p-1 font-weight-light gray--text text--darken-1">
            {{ $t('button.dashboard.back') }}
          </span>
          <span v-else class="p-1 font-weight-light gray--text text--darken-1">
            {{ $t('button.maybeLater') }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OnboardingNoActivePlans from "../illustrations/onboarding-no-active-plans.vue";

export default {
  components: {
    OnboardingNoActivePlans,
  },
  props: {
    vars: Object,
    isOpen: Boolean,
    selectedService: Object,
  },
  computed: {
    orderNewServiceLink() {
      return this.$store.state.home.user.order_service_link;
    },
    upgradePlanLink() {
      if (this.selectedService.id && this.selectedService.plan_upgrade_link) {
        return this.selectedService.plan_upgrade_link + '?paupgradeserviceid=' + this.selectedService.id;
      }
      return "";
    },
  },
  watch: {
    isOpen: function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content::v-deep {
  .v-dialog {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.close-model {
  position: absolute;
  top: -40px;
  right: -8px;
}
.v-card::v-deep {
  .v-card__text {
    -webkit-line-clamp: unset !important;
  }
  @media (max-width: 480px) {
    .v-card__text,
    .v-card__actions {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
  }
}
</style>
