<template>
  <div class="d-flex">
    <v-dialog
      v-model="isOpen"
      persistent
      ref="dialog"
      transition="custom-dialog-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <slot name="default" v-bind="{ attrs, on }" />
      </template>

      <div class="card-overlay" @click="$refs.dialog.animateClick()" />
      <v-card
        style="width: 720px"
        v-if="!isCustomPrivilegesMenuOpen"
        elevation="4"
      >
        <v-card-title class="p-t-5">
          <v-icon size="24" color="primary">$share2Icon</v-icon>
          <h5 class="ml-4 mb-0 font-weight-bold heading--text">
            <template v-if="multiInstances.length"
              >{{ $t('heading.instance.modal.sharedAccess.title.other', {length: multiInstances.length}) }}</template
            >
            <template v-else>{{ $t('heading.instance.modal.sharedAccess.title.one', {title: instance.title}) }}</template>
          </h5>
        </v-card-title>

        <v-card-text>
          <div
            class="d-flex align-start mb-8"
            :class="
              $vuetify.breakpoint.mdAndDown ? 'justify-end flex-wrap' : ''
            "
          >
            <div
              class="d-flex flex-column w-100"
              :style="
                $vuetify.breakpoint.mdAndDown
                  ? ''
                  : 'max-width: calc(100% - 251px)'
              "
            >
              <span class="text--body mb-3">{{ $t("form.label.user.choose") }}</span>

              <!-- Search w autocomplete pewnie lepiej będzie zrobić po stronie serwera i tam filtrować wyniki -->
              <v-combobox
                :placeholder="$t('form.placeholder.email')"
                outlined
                :items="filteredUsers"
                :loading="userSuggestionsLoading"
                :search-input.sync="userSuggestionsSearch"
                v-model="userList"
                multiple
                append-icon="$close"
                deletable-chips
                chips
                :filter="
                  (item, queryText) => {
                    return (
                      (item.email + item.name)
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1
                    );
                  }
                "
                hide-details=""
                :class="$vuetify.breakpoint.mdAndDown ? '' : 'mr-4'"
                item-value="email"
                item-text="name"
                :menu-props="{
                  offsetY: true,
                  nudgeBottom: '8px',
                  closeOnContentClick: false,
                  contentClass:
                    'custom-dropdown-select custom-dropdown-select--autocomplete custom-dropdown-select--autocomplete-share',
                }"
              >
                <template v-slot:selection="{ item }">
                  <v-chip
                    small
                    :class="isEmailCorrect(item) ? '' : 'v-chip--error'"
                  >
                    <span class="text-truncate">
                      {{ typeof item === "object" ? item.name : item }}
                    </span>
                    <v-icon
                      @click="chipRemove(item)"
                      v-ripple
                      size="16"
                      class="ml-1 delete-button"
                    >
                      $close
                    </v-icon>
                  </v-chip>
                </template>
                <template v-slot:append>
                  <v-icon size="16">$chevrondown</v-icon>
                </template>
                <template v-slot:item="{ item }">
                  <div class="d-flex align-center">
                    <v-avatar size="32px">
                      <v-img :src="getAvatarUrl(item.email, 32)" />
                    </v-avatar>
                    <div class="d-flex flex-column ml-3">
                      <p
                        class="s p-2 mb-0 font-weight-bold gray--text text--darken-2"
                      >
                        {{ item.name }}
                      </p>
                      <p
                        class="p-3 mb-0 mt-1 gray--text"
                        v-if="item.name !== item.email"
                      >
                        {{ item.email }}
                      </p>
                    </div>
                  </div>
                </template>
              </v-combobox>
            </div>
            <div
              class="d-flex align-center justify-end"
              :class="[
                $vuetify.breakpoint.width < 475
                  ? 'flex-column align-stretch'
                  : '',
                $vuetify.breakpoint.mdAndDown ? 'mt-4 w-100' : '',
              ]"
              style="max-width: 100%"
              :style="$vuetify.breakpoint.width < 475 ? 'width: 100%' : ''"
            >
              <div
                class="d-flex mr-4 flex-column"
                :class="{
                  'w-100 ': $vuetify.breakpoint.width < 975,
                }"
              >
                <span class="text--body mb-3">{{ $t("form.label.role") }}</span>

                <select-dropdown
                  :style="
                    $vuetify.breakpoint.mdAndDown < 475
                      ? 'min-width: 100%;'
                      : 'min-width: 168px;'
                  "
                  :itemText="'text'"
                  :itemValue="'key'"
                  :items="roles"
                  :value="role"
                  class="combobox"
                  :placeholder="$t('form.placeholder.role')"
                  :block="$vuetify.breakpoint.width < 475"
                  @update:value="newRoleChanged"
                />
              </div>

              <v-btn
                :loading="sendInviteButtonLoading"
                @click="sendInvite"
                large
                color="primary"
                style="margin-top: 34px"
                outlined
              >
                {{ $t('button.sharedAccess.invite') }}
              </v-btn>
            </div>
          </div>

          <div>
            <h6 class="">{{ $t("heading.instance.modal.sharedAccess.table.title") }}</h6>
            <div class="d-flex flex-column">
              <div class="d-flex justify-space-between mt-3 font-weight-600">
                <span class="p-5">
                  {{ $t("general.person", {count: assignedUsers.length + 1}) }}
                </span>
                <div
                  style="width: 223px"
                  class="d-flex align-center justify-space-between"
                >
                  <span class="p-5">{{ $t("table.header.role") }}</span>
                  <span class="p-5 mr-6">{{ $t("table.header.remove") }}</span>
                </div>
              </div>

              <div class="user-list">
                <InstanceShareItemOwner
                  :instance="instance"
                  v-if="instance.owner"
                />

                <InstanceShareItem
                  v-for="(item, index) in assignedUsers"
                  v-show="!pendingRemove.has(item.email)"
                  :key="item.email + index"
                  :item="item"
                  :hasPendingChanges="checkIfItemHasPendingChanges(item)"
                  :roles="roles"
                  :mixedRoles="item.role == 'mixed'"
                  @role-changed="roleChanged"
                  @user-remove-access="unassignUser"
                  @resend-button-clicked="resendInvite"
                  :resending="resending.includes(item.email)"
                />
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="d-flex flex-column pb-4 px-8 pt-8">
          <v-btn
            x-large
            elevation="0"
            color="primary"
            block
            :loading="saveChangesButtonLoading"
            @click="saveChanges()"
            >
              <span v-if="hasPendingChanges">{{ $t('button.sharedAccess.pending') }}</span>
              <span v-else>{{ $t('button.save') }}</span>
          </v-btn>
          <v-btn
            x-large
            elevation="0"
            class="mx-0 mt-2"
            color="gray"
            text
            block
            @click="cancelChanges()"
          >
            <span class="p-1 font-weight-light gray--text text--darken-1">
              {{ $t('button.cancel') }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-else elevation="4">
        <v-card-title class="p-t-5">
          <v-icon size="24" color="primary">$settings</v-icon>
          <h5 class="ml-4 mb-0 font-weight-bold heading--text">
            {{ $t('heading.instance.modal.sharedAccess.privileges.title') }}
          </h5>
        </v-card-title>
        <v-card-text>
          <v-expansion-panels accordion class="elevation-0">
            <v-expansion-panel
              v-for="group in groups"
              :key="'expansion-panel.' + group.key"
            >
              <v-expansion-panel-header class="align-start">
                <Checkbox
                  :indeterminate="groupIndeterminate(group.key)"
                  :checked="groupChecked(group.key)"
                  @change="toggleGroup(group.key)"
                />
                <div class="d-flex flex-column">
                  <h6>
                    {{ group.text }}
                    <v-icon size="16" class="ml-1 mb-1 chevron"
                      >$chevrondown</v-icon
                    >
                  </h6>
                  <span v-if="group.description" class="p-5 error--text">{{ group.description }}</span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  class="d-flex flex-row align-center"
                  v-for="item in groupPrivileges(group.key)"
                  :key="item.key"
                >
                  <Checkbox
                    v-if="customPrivilegesUser"
                    :checked="
                      customPrivilegesUser.customPrivileges.includes(item.key)
                    "
                    @change="togglePrivilege(item.key)"
                    :label="item.text"
                    :class="item.color ? `checkbox--${item.color}-label` : ''"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions class="d-flex flex-column pb-4 px-8 pt-8">
          <v-btn
            x-large
            elevation="0"
            color="primary"
            block
            @click="saveCustomPrivileges"
          >
            {{ $t('button.save') }}
          </v-btn>
          <v-btn
            x-large
            elevation="0"
            class="mx-0 mt-2"
            color="gray"
            text
            block
            @click="closeCustomPrivilegesMenu"
          >
            <span class="p-1 font-weight-light gray--text text--darken-1">
              {{ $t('button.cancel') }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import moment from "moment";
import { getAvatarUrl } from "@/apis/HelperFunctions";
import { getUserPrivilegesGroups } from "@/apis/HelperData.js";
import { UserPrivilegesList } from "@/apis/HelperData.js";
import SelectDropdown from "../SelectDropdown.vue";
import avatar from "../../assets/img/avatars/1.png";
import InstanceShareItem from "../instances/InstanceShareItem.vue";
import Checkbox from "../buttons/Checkbox.vue";
import InstanceShareItemOwner from "../instances/InstanceShareItemOwner.vue";
import i18next from "i18next";

export default {
  components: {
    SelectDropdown,
    InstanceShareItem,
    Checkbox,
    InstanceShareItemOwner,
  },
  props: {
    instance: {
      type: Object,
      default() {
        return {
          id: 0,
          sharedAccess: [],
        };
      },
    },
    multiInstances: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    currentPrivileges()
    {
      if (this.instance.id) {
        return this.instance.privileges;
      }
      let privs = [];
      for (let instance of this.multiInstances) {
        for (let privilege of instance.privileges) {
          if (!privs.includes(privilege)) {
            privs.push(privilege);
          }
        }
      }
      return privs;
    },
    groups() {
      return getUserPrivilegesGroups(i18next).filter((g) => {
        return this.groupPrivileges(g.key).length > 0;
      });
    },
    privileges() {
      return UserPrivilegesList.map(item => {
        return {
          key: item.key,
          group: item.group,
          text: this.$t(`privileges.list.${item.key}`)
        }
      });
    },
    filteredUsers() {
      return this.userSuggestions.filter((item) => {
        var flag = true;

        this.assignedUsers.map((assigned) => {
          if (item.email === assigned.email) {
            flag = false;
          }
        });

        return flag;
      });
    },
    assignedUsers() {
      if (this.multiInstances.length) {
        let users = new Map();
        for (let instance of this.multiInstances) {
          for (let user of instance.sharedAccess) {
            if (users.has(user.email)) {
              let existingUser = users.get(user.email);
              if (existingUser.role != user.role) {
                existingUser.role = "mixed";
              }
              existingUser.customPrivileges = [];
              if (!existingUser.invitationPending) {
                existingUser.invitationPending = !user.accepted_at;
              }
              if (user.invited_at > existingUser.invited_at) {
                existingUser.invited_at = user.invited_at;
                existingUser.invited_at_text = moment(
                  user.invited_at
                ).fromNow();
              }
              if (
                user.notification_sent_at > existingUser.notification_sent_at
              ) {
                existingUser.notification_sent_at = user.notification_sent_at;
                existingUser.notification_sent_at_text = moment(
                  user.notification_sent_at
                ).fromNow();
              }
              if (user.resend_available_at > existingUser.resend_available_at) {
                existingUser.resend_available_at = user.resend_available_at;
              }
              existingUser.instancesIds.push(instance.id);
              continue;
            }
            users.set(user.email, {
              name: user.name || user.email,
              email: user.email,
              role: user.role,
              customPrivileges: user.custom_privileges || [],
              invitationPending: !user.accepted_at,
              invited_at: user.invited_at,
              invited_at_text: moment(user.invited_at).fromNow(),
              notification_sent_at: user.notification_sent_at,
              notification_sent_at_text: moment(
                user.notification_sent_at
              ).fromNow(),
              resend_available_at: user.resend_available_at,
              instancesIds: [instance.id],
            });
          }
        }
        users.forEach((user) => {
          if (user.instancesIds.length != this.multiInstances.length) {
            user.role = "mixed";
          }
        });
        return [...users.values()];
      }
      return this.instance.sharedAccess.map((user) => {
        return {
          name: user.name || user.email,
          email: user.email,
          role: user.role,
          customPrivileges: user.custom_privileges || [],
          invitationPending: !user.accepted_at,
          invited_at_text: moment(user.invited_at).fromNow(),
          notification_sent_at_text: moment(
            user.notification_sent_at
          ).fromNow(),
          resend_available_at: user.resend_available_at,
        };
      });
    },
  },
  watch: {
    isOpen: function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
    userSuggestionsSearch() {
      if (this.userSuggestionsFetched) {
        return;
      }
      this.userSuggestionsFetched = true;
      this.userSuggestionsLoading = true;
      Api.get("/invitation-suggestions")
        .then((response) => {
          this.userSuggestions = response.data.data;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.userSuggestionsLoading = false;
        });
    },
  },
  data: function () {
    return {
      getAvatarUrl,
      isOpen: false,
      isCustomPrivilegesMenuOpen: false,
      avatar,
      userList: [],
      userSuggestions: [], //load from api
      userSuggestionsFetched: false,
      userSuggestionsLoading: false,
      userSuggestionsSearch: "",
      sendInviteButtonLoading: false,
      saveChangesButtonLoading: false,
      role: "view_only",
      roles: [
        {
          text: 'sharedAccess.role.viewOnly',
          key: "view_only",
          tooltip: this.$t('tooltip.instance.sharedAccess.role.viewOnly')
        },
        {
          text: 'sharedAccess.role.viewAndSso',
          key: "view_and_sso",
          tooltip: this.$t('tooltip.instance.sharedAccess.role.viewAndSso')
        },
        {
          text: 'sharedAccess.role.developer',
          key: "developer",
          tooltip: this.$t('tooltip.instance.sharedAccess.role.developer')
        },
        {
          text: 'sharedAccess.role.fullAccess',
          key: "full_access",
          tooltip: this.$t('tooltip.instance.sharedAccess.role.fullAccess')
        },
        {
          text: 'sharedAccess.role.custom',
          key: "custom",
          tooltip: this.$t('tooltip.instance.sharedAccess.role.custom')
        },
      ],
      customPrivileges: [],
      pendingChanges: new Map(),
      pendingRemove: new Map(),
      customPrivilegesUser: null,
      hasPendingChanges: false,
      lastResponse: {},
      resending: [],
    };
  },
  methods: {
    groupPrivileges(group) {
      return this.privileges.filter((p) => {
        return p.group == group && this.currentPrivileges.includes(p.key);
      });
    },
    groupChecked(group) {
      for (let priv of this.groupPrivileges(group)) {
        if (!this.customPrivilegesUser.customPrivileges.includes(priv.key)) {
          return false;
        }
      }
      return true;
    },
    groupIndeterminate(group) {
      if (this.groupChecked(group)) {
        return false;
      }
      for (let priv of this.groupPrivileges(group)) {
        if (this.customPrivilegesUser.customPrivileges.includes(priv.key)) {
          return true;
        }
      }
      return false;
    },
    toggleGroup(group) {
      let checked = this.groupChecked(group);
      for (let priv of this.groupPrivileges(group)) {
        checked
          ? this.uncheckPrivilege(priv.key)
          : this.checkPrivilege(priv.key);
      }
    },
    checkPrivilege(key) {
      if (!this.customPrivilegesUser.customPrivileges.includes(key)) {
        this.customPrivilegesUser.customPrivileges.push(key);
      }
    },
    uncheckPrivilege(key) {
      let index = this.customPrivilegesUser.customPrivileges.indexOf(key);
      if (index !== -1) {
        this.customPrivilegesUser.customPrivileges.splice(index, 1);
      }
    },
    togglePrivilege(key) {
      if (!this.customPrivilegesUser.customPrivileges.includes(key)) {
        this.checkPrivilege(key);
        return;
      }
      this.uncheckPrivilege(key);
    },
    checkIfItemHasPendingChanges: function (item) {
      if (this.pendingChanges.size) {
        var flag = false;
        this.pendingChanges.forEach((change) => {
          if (change.email === item.email) {
            flag = true;
          }
        });
        return flag;
      } else {
        return false;
      }
    },
    sendInvite() {
      let request = { role: this.role };
      if (this.role == "custom") {
        request.custom_privileges = this.customPrivileges;
      }
      request.emails = this.userList.map((item) => {
        if (typeof item === "object" && item.email) {
          return item.email;
        }
        return item;
      });

      let promises = [];

      if (this.multiInstances.length) {
        for (let instance of this.multiInstances) {
          promises.push(this.sendInviteRequest(instance, request));
        }
      } else {
        promises.push(this.sendInviteRequest(this.instance, request));
      }

      this.sendInviteButtonLoading = true;

      Promise.all(promises)
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.sendInviteButtonLoading = false;
        });
    },
    sendInviteRequest(instance, request) {
      return Api.post(`/instances/${instance.id}/shared-access`, request).then(
        (response) => {
          instance.setSharedAccessData(response.data.data);
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.sharedAccess.invite.success'),
          });
          return response;
        }
      );
    },
    resendInvite(user) {
      let request = { email: user.email };

      let promises = [];
      if (this.multiInstances.length) {
        for (let instance of this.multiInstances) {
          let instanceInviteEmails = instance.sharedAccess.map((sa) => {
            return sa.email;
          });
          if (!instanceInviteEmails.includes(user.email)) {
            continue;
          }
          promises.push(this.resendInviteRequest(instance, request));
        }
      } else {
        promises.push(this.resendInviteRequest(this.instance, request));
      }

      this.resending.push(user.email);
      Promise.all(promises)
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.resending = this.resending.filter((e) => e != user.email);
        });
    },
    resendInviteRequest(instance, request) {
      return Api.post(
        `/instances/${instance.id}/shared-access/resend-invite`,
        request
      ).then((response) => {
        instance.setSharedAccessData(response.data.data);
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: this.$t('notification.instance.sharedAccess.resendInvite.success', {title: instance.title}),
        });
        return response;
      });
    },
    roleChanged(item, newValue) {
      item.role = newValue;
      this.pendingChanges.set(item.email, { ...item });
      this.refreshChanges();
      if (newValue == "custom") {
        this.openCustomPrivilegesMenu(item);
      }
    },
    newRoleChanged(event) {
      this.role = event.key;
      if (event.key === "custom") {
        this.openCustomPrivilegesMenu();
      }
    },
    openCustomPrivilegesMenu(user = null) {
      if (user) {
        this.customPrivilegesUser = user;
      } else {
        this.customPrivileges = [];

        this.customPrivilegesUser = {
          role: "custom",
          customPrivileges: [],
        };
      }
      this.isCustomPrivilegesMenuOpen = true;
    },
    saveCustomPrivileges() {
      if (!this.customPrivilegesUser.email) {
        this.customPrivileges = [...this.customPrivilegesUser.customPrivileges];
        this.customPrivilegesUser = null;

        this.refreshChanges();
      }
      if (this.customPrivilegesUser) {
        this.pendingChanges.set(this.customPrivilegesUser.email, {
          ...this.customPrivilegesUser,
        });
        this.refreshChanges();
      }
      this.closeCustomPrivilegesMenu();
    },
    closeCustomPrivilegesMenu() {
      this.isCustomPrivilegesMenuOpen = false;
      this.customPrivilegesUser = null;
    },
    closeModal() {
      this.closeCustomPrivilegesMenu();
    },
    unassignUser(user) {
      this.pendingRemove.set(user.email, user);
      this.refreshChanges();
    },
    refreshChanges() {
      // computed/watch doesn't work on map/set in vue v2
      this.hasPendingChanges =
        this.pendingChanges.size > 0 || this.pendingRemove.size > 0;
    },
    cancelChanges() {
      this.pendingChanges.clear();
      this.pendingRemove.clear();

      this.role = "view_only";
      this.userList = [];

      this.refreshChanges();

      this.isOpen = false;
    },
    saveChanges() {
      if (!this.hasPendingChanges) {
        return this.cancelChanges();
      }
      if (this.multiInstances.length) {
        return this.multiInstancesSaveChanges();
      }

      let promises = [];
      let updateRequest = { users: [] };
      let removeRequest = { users: [] };

      if (this.pendingChanges.size > 0) {
        this.pendingChanges.forEach((user) => {
          updateRequest.users.push({
            email: user.email,
            role: user.role,
            custom_privileges: user.customPrivileges,
          });
        });
        promises.push(this.updateRequest(this.instance, updateRequest));
      }

      if (this.pendingRemove.size > 0) {
        this.pendingRemove.forEach((user) => {
          removeRequest.users.push({
            email: user.email,
          });
        });
        promises.push(this.deleteRequest(this.instance, removeRequest));
      }

      if (!promises.length) {
        return;
      }

      this.saveChangesButtonLoading = true;

      Promise.all(promises)
        .then(() => {
          this.pendingChanges.clear();
          this.pendingRemove.clear();

          this.refreshChanges();

          this.isOpen = false;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.saveChangesButtonLoading = false;
        });
    },
    multiInstancesSaveChanges() {
      let promises = [];

      if (this.pendingChanges.size > 0) {
        for (let instance of this.multiInstances) {
          let updateRequest = { users: [] };
          this.pendingChanges.forEach((user) => {
            if (!user.instancesIds.includes(instance.id)) {
              promises.push(
                this.sendInviteRequest(instance, {
                  emails: [user.email],
                  role: user.role,
                  custom_privileges: user.customPrivileges,
                })
              );
              return;
            }
            updateRequest.users.push({
              email: user.email,
              role: user.role,
              custom_privileges: user.customPrivileges,
            });
          });
          if (updateRequest.users.length) {
            promises.push(this.updateRequest(instance, updateRequest));
          }
        }
      }

      if (this.pendingRemove.size > 0) {
        for (let instance of this.multiInstances) {
          let removeRequest = { users: [] };
          this.pendingRemove.forEach((user) => {
            if (user.instancesIds.includes(instance.id)) {
              removeRequest.users.push({
                email: user.email,
              });
            }
          });
          if (removeRequest.users.length) {
            promises.push(this.deleteRequest(instance, removeRequest));
          }
        }
      }

      if (!promises.length) {
        return;
      }

      this.saveChangesButtonLoading = true;
      Promise.all(promises)
        .then(() => {
          this.pendingChanges.clear();
          this.pendingRemove.clear();
          this.refreshChanges();
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.saveChangesButtonLoading = false;
        });
    },
    updateRequest(instance, request) {
      return Api.put(`/instances/${instance.id}/shared-access`, request).then(
        (response) => {
          let emailList = request.users.map((user) => {
            return user.email;
          });
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.sharedAccess.update.success', {list: emailList.join(", ")})
          });
          this.lastResponse = response.data.data;
          instance.setSharedAccessData(this.lastResponse);
          return response;
        }
      );
    },
    deleteRequest(instance, request) {
      return Api.delete(`/instances/${instance.id}/shared-access`, {
        data: request,
      }).then((response) => {
        let emailList = request.users.map((user) => {
          return user.email;
        });
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: this.$t('notification.instance.sharedAccess.delete.success', {list: emailList.join(", ")})
        });
        this.lastResponse = response.data.data;
        instance.setSharedAccessData(this.lastResponse);
        return response;
      });
    },
    //
    isEmailCorrect: function (item) {
      if (typeof item === "object") {
        return true;
      } else {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(item)) {
          return true;
        } else {
          return false;
        }
      }
    },
    chipRemove: function (itemToRemove) {
      if (Array.isArray(this.userList)) {
        this.userList = this.userList.filter((item) => {
          if (typeof itemToRemove === "object") {
            if (
              item.name === itemToRemove.name &&
              item.email === itemToRemove.email
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return item !== itemToRemove;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  .v-card__text {
    -webkit-line-clamp: none;
    -webkit-box-orient: vertical;
  }

  @media (max-width: 975px) {
    .v-card__text {
      padding: 0 24px !important;
    }

    .v-card__title {
      padding: 24px !important;
    }

    .v-card__actions {
      padding: 24px 24px 12px 24px !important;
    }

    .combobox {
      width: 100%;
      flex-grow: 1;
    }
  }
}

.v-expansion-panel::v-deep {
  &:not(:last-child) {
    margin-bottom: 32px;
  }

  &::before,
  &::after {
    display: none;
  }

  .v-expansion-panel-content {
    &__wrap {
      padding-bottom: 0px;
      padding-top: 32px;

      > div:not(:last-child) {
        margin-bottom: 16px;
      }
    }
    .checkbox {
      label {
        margin-left: 12px;
        color: var(--v-gray-darken2);
        font-size: $font-size-base;
        line-height: $line-height-base;
        font-weight: $font-weight-light;
      }
      &--error-label {
        label {
          font-weight: $font-weight-bold;
          color: var(--v-error-base);
        }
      }
      &--info-label {
        label {
          font-weight: $font-weight-bold;
          color: var(--v-info-base);
        }
      }
    }
  }

  .v-expansion-panel-header {
    padding: 0;
    min-height: unset;
    background: transparent !important;

    .chevron {
      color: var(--v-gray-lighten1);
    }

    &--active {
      .chevron {
        color: var(--v-primary-base) !important;
        transform: rotate(180deg);
        transition: color 0.24s ease, transform 0.24s ease;
      }
    }

    &:hover {
      background: transparent !important;
    }

    & > *:not(.v-expansion-panel-header__icon) {
      flex: 0 1 auto;
    }
  }
}

.v-autocomplete::v-deep {
  .v-chip {
    background: transparent;
    border: 1px solid var(--v-gray-lighten3);
    border-radius: 5px;
    color: var(--v-gray-darken2);
    padding: 4px;
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    font-weight: $font-weight-normal;
    height: 28px;
    // max-width: calc(100% - 32px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .v-chip__content {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .delete-button {
      color: var(--v-gray-darken1);
    }

    &.v-chip--error {
      border: 1px solid var(--v-error-base);
      color: var(--v-error-base);

      .delete-button {
        color: var(--v-error-base);
      }
    }
  }

  .v-input__append-inner {
    padding-left: 0px !important;
  }

  .v-input__control .v-input__slot {
    max-height: unset !important;
    height: fit-content;

    .v-select__selections {
      min-height: unset;
      padding: 0;

      input {
        min-width: 126px;
        max-width: unset;
        max-height: unset;
      }
    }
  }

  .v-select__selections {
    padding: 0px 0 !important;
  }

  input {
    padding: 0px !important;
  }

  &.v-select--is-menu-active {
    .v-input__append-inner {
      .v-icon {
        color: map-get($primary, darken1) !important;
        transform-origin: center;
        transform: rotate(180deg);
      }
    }
  }
}

.checkbox::v-deep {
  margin-right: 12px;

  .v-input.v-input--checkbox .v-input__control .v-input__slot {
    min-height: unset;
  }
}

.input-dropdown::v-deep {
  .v-select__selections .v-select__selection {
    font-size: $font-size-base;
    line-height: $line-height-base;
    font-weight: $font-weight-light;
    color: var(--v-text-base);
    margin: 0px;
  }
}

.user-list {
  display: flex;
  flex-direction: column;
  max-height: 260px;
  overflow-y: auto !important;
  margin-left: -8px;
  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: transparent;
    max-width: 8px;
    border: 3px solid transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--v-gray-lighten2);
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }
}

.v-avatar {
  border-color: var(--v-gray-lighten4) !important;
  border-style: solid !important;
  border-width: 1px !important;
}
</style>
